import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Wrapper from "../components/layout/wrapper/wrapper"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import Seo from "../components/seo/seo"
import BlockContent from "../components/blockContent/blockContent"

const PrivacyPolicy = ({ data, pageContext }) => {
  const { content, seo } = data.sanityPrivacyPolicy.tabs

  return (
    <Layout>
      <Seo title={content.title} path={pageContext.path} {...seo} />
      <HeroSimple blocks={content._rawHero[0].children} subtitle={content.subtitle} small={true} />
      <Wrapper>
        <div className="grid">
          <div className="gridItem small-12 medium-10 medium-offset-1">
            <BlockContent blocks={content._rawRichText} />
          </div>
        </div>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityPrivacyPolicy(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _updatedAt
      tabs {
        content {
          title
          _rawHero
          subtitle
          _rawRichText(resolveReferences: { maxDepth: 20 })
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
  }
`

export default PrivacyPolicy
